.list-group {
  li {
    position: relative;
    a {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
    &.active {
      a {
        text-decoration: none;
        span {
          color: #FFF;
        }
      }
    }
  }
}

.ngdialog.ngdialog-theme-plain.custom-width .ngdialog-content {
  width: 650px;
}
.ngdialog.ngdialog-theme-plain.custom-width2 .ngdialog-content {
  width: 850px;
}

.navbar-brand {
  img {
    width: 100px;
  }
}

.navbar {
  min-height: 70px;
}
